<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Employee Bulk Update",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Employee Bulk Update",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Employee Bulk Update",
          active: true,
        },
      ],
      file: null,
      uploading: false,
    };
  },
  methods: {
    async upload() {
      if (!this.file) {
        this.apiFormHandler("File upload is required");
      } else {
        this.uploading = true;
        let url = `${this.ROUTES.employee}/upload-contract-end-date`;
        const fd = new FormData();
        fd.append("contract_end", this.file);
        await this.apiPost(url, fd, "Upload Bulk Update File")
          .then(async (res) => {
            const { data } = res;
            if (data && data === "Uploaded Successfully") {
              url = `${this.ROUTES.employee}/process-contract-end-date`;
              await this.apiGet(url, "Process Bulk Update File")
                .then((res) => {
                  const { data } = res;
                  if (data) {
                    this.$router.push({ name: "manage-employees" }).then(() => {
                      this.apiResponseHandler(data, "Process Bulk Update File");
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.uploading = false;
                });
            }
          })
          .catch(() => {
            this.uploading = false;
          });
        this.uploading = false;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button
        class="btn btn-success"
        @click="$router.push({ name: 'manage-employees' })"
      >
        <i class="mdi mdi-arrow-left mr-2"></i>
        Manage Employees
      </b-button>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4">
              <h5 class="font-size-14 mb-0">
                Update Employee Contract End Dates
              </h5>
            </div>
            <div class="form-group">
              <label for="file">
                File Upload <span class="text-danger">*</span>
              </label>
              <b-form-file id="file" v-model="file" accept=".xlsx,.xls,.csv" />
            </div>
            <div class="mt-4">
              <button
                v-if="!uploading"
                @click="upload"
                class="btn btn-success w-100 mr-3"
              >
                Submit
              </button>
              <button v-else disabled class="btn btn-success w-100 mr-3">
                Submitting...
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
